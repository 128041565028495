.HexagonHover {
  > div > div > div > div {
    background-color: rgba(255, 255, 255, 0.88);
    transform: scale(1.075);
    transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    &:before {
      position: absolute;
      top: 0;
      left: -75%;
      z-index: 2;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
      );
      transform: skewX(-25deg);
      animation: shine 0.75s;
    }
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}
