.CaseStudy {
  padding: 4rem 0;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -101px;
    width: 100%;
    height: 0;
    border-top: 101px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 100vw solid #3B1C4E;

    @media only screen and (max-width: 575.98px) {
      top: -50px;
      border-top: 50px solid transparent;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -100px;
    width: 100%;
    z-index: 1;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 100px solid transparent;
    border-left: 100vw solid #3B1C4E;

    @media only screen and (max-width: 575.98px) {
      bottom: -50px;
      border-bottom: 50px solid transparent;
    }
  }

  h3 {
    color: #f8e3ff;
    font-size: 1.75rem;
    font-weight: 900;
    margin: 0;
    padding: 0;
    text-align: center;

    @media only screen and (max-width: 575.98px) {
      font-size: 21px;
    }
  }

  p {
    font-size: 18px;
    color: #f8e3ff;
  }

  .Results {
    text-align: center;

    .ResultItem {
      color: #ffffff;
      background: rgba(255, 255, 255, 0.02);
      border: 1px solid rgba(255, 255, 255, 0.16);
      border-radius: 16px;
      margin: 0 auto;
      padding: 35px 20px;
      width: 18rem;

      @media only screen and (max-width: 767.98px) {
        margin: 0 auto 2rem;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      height: 80px;
      width: 80px;
      display: flex;
      margin: 1rem auto 0;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      background: #6D2CB9;
      box-shadow: 0px 0px 26.2px rgba(109, 44, 185, 0.22);
      backdrop-filter: blur(100px);
      border-radius: 60px;
    }

    p {
      font-size: 20px;
      color: #ffffff;
    }

    span {
      font-size: 14px;
      color: #ffffff;
    }
  }
}