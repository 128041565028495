.Hero {
  background: #110716 url('./herobg.svg') no-repeat top center;
  background-size: 220%;
  box-sizing: border-box;
  padding-top: 7rem;
  padding-bottom: 2rem;
  position: relative;

  @media only screen and (max-width: 992px) {
    background-size: cover;
  }

  @media only screen and (max-width: 575.98px) {
    padding-bottom: 1rem;
  }

  h1 {
    font-family: 'NunitoSans';
    font-style: normal;
    font-size: 64px;
    line-height: 120%;
    animation: move 5s linear infinite;
    background-image: linear-gradient(to right, #EF3355, #C766F4, #8C66F4, #C766F4, #EF3355);
    background-size: 200% auto;
    background-clip: text;
    color: transparent;

    @keyframes move {
      to {
        background-position: 200% center;
      }
    }

    @media only screen and (max-width: 1398px) {
      font-size: 50px;
      line-height: 55px;
    }

    @media only screen and (max-width: 1198px) {
      font-size: 40px;
      line-height: 45px;
    }

    @media only screen and (max-width: 575.98px) {
      font-size: 38px;
      line-height: 2.75rem;
      margin-bottom: 1rem;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 100vw solid #3B1C4E;

    @media only screen and (max-width: 575.98px) {
      border-top: 50px solid transparent;
    }
  }
}
