@import '~bootstrap/scss/bootstrap';

.SubscriptionForm {
  @extend .mt-5;
  @extend .shadow;

  background-color: #271135 !important;
  border-radius: 8px !important;

  h1 {
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
    color: transparent;
  }
}

.Title {
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
  color: transparent;
  display: inline-block;
  font-weight: 900;
  margin-bottom: 1rem;
}

.Subtitle {
  margin-bottom: 1.25em !important;
  color: #ffffff !important;
}

.Email {
  border: 2px solid #ffffff;
  border-radius: 999px !important;
  display: inline !important;
  padding-left: 1rem;
  padding-right: 1rem;
  width: calc(100% - 200px) !important;
}

.SubscribeButton {
  color: #fff;
  background-color: #EF3355;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .141176);
  background-image: linear-gradient(45deg, #EF3355, #C766F4, #EF3355, #C766F4);
  background-repeat: no-repeat;
  background-size: 300%;
  background-position: 0;
  transition-property: background-position, box-shadow;
  transition-timing-function: ease;
  transition-duration: .3s;
  border: 0;
  border-radius: 999px !important;
  padding: 10px 25px;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 2rem;

  &:hover {
    background-position: 100%;
    color: #fff;
    background-color: #0aa7e5;
    border-color: #0a9ed8;
    box-shadow: 0 4px 8px 0 rgba(0, 166, 230, .28) !important
  }
}
