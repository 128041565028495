#blogSubscriptionForm {
  @media screen and (max-width: 992px) {
    #subscription-email {
      display: block !important;
      width: 100% !important;
    }

    button {
      margin: 1rem 0 0;
    }
  }
}
