@import '../../styles/effects/gradient-button';

.BookMeeting {
  background: linear-gradient(98.43deg, #EF3455 -30.22%, #C565F3 162.31%);
  box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  width: 100%;
  padding: 70px 32px;
  margin-top: 6rem;

  @media only screen and (max-width: 991.98px) {
    margin-top: 2rem;
  }

  h4 {
    font-family: 'NunitoSans';
    font-weight: 800;
    font-size: 32px;
    line-height: 120%;
    color: #ffffff;
    text-transform: uppercase;
  }

  p {
    color: #ffffff;
    opacity: 0.7;
  }

  a {
    display: inline-block;
    margin-top: 1rem;
    background-color: #ffffff;
    text-transform: none;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #E73F77;
    padding: 14px 28px;
    border-radius: 100px;
    border: 1px solid #ffffff;
    transition: all .25s ease;

    &:hover {
      background-color: transparent;
      color: #ffffff;
      transition: all .25s ease;
    }
  }
}
