.Integration {
  h3 {
    background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  h5 {
    color: #f8e3ff;
    font-size: 26px;
    font-weight: 700;
  }

  p {
    color: #f8e3ff;
    font-weight: 400;

    a {
      color: #a53a82;
      text-decoration: none;
      transition: all .25s ease;

      &:hover {
        -webkit-background-clip: text !important;
        background-clip: text !important;
        background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%) !important;
        color: transparent !important;
        opacity: 1;
        text-decoration: none;
        transition: all .25s ease;
      }
    }
  }

  ul {
    margin-bottom: 36px;
    padding-left: 16px;
    list-style-type: disc;

    li {
      color: #f8e3ff;
      margin-bottom: 9px;
    }
  }

  pre {
    background: #190e2b;
    padding: 1rem;
    margin: 1.5rem 0;

    code {
      color: #4af626;
      font-weight: 600;
    }
  }
}
