.Benefits {
  position: relative;
  background-color: #110716;
  padding-bottom: 8rem;

  h1 {
    font-family: 'NunitoSans';
    font-weight: 700;
    font-size: 48px;
  }

  .Box {
    padding: 24px 32px;
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 8px;
    margin-bottom: 25px;
    text-align: center;
    transition: all .25s ease;

    p {
      margin: 0;
      white-space: pre-line;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
      opacity: 0.7;
      transition: all .25s ease;
      min-height: 100px;

      @media only screen and (max-width: 1200px) {
        min-height: 60px;
      }

      @media only screen and (max-width: 575.98px) {
        font-size: 16px;
        min-height: auto;
      }
    }

    .BoxImage {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 auto 1rem;
      padding: .15rem;
      position: relative;
      transition: all .25s ease;
      width: 50px;
      height: 50px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      filter: drop-shadow(0px 0px 26.2px rgba(230, 62, 121, 0.2));
      backdrop-filter: blur(100px);
      border-radius: 30px;

      img {
        display: block;
        width: 80%;
      }
    }

    &:hover {
      background: #3E0C2B;
      border: 1px solid #3E0C2B;
      box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.12);
      transition: all .25s ease;

      p {
        opacity: 1;
        transition: all .25s ease;
      }
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 100vw solid #3B1C4E;

    @media only screen and (max-width: 575.98px) {
      border-top: 50px solid transparent;
    }
  }
}
