.MultiFactorAuthentication {
  color: #f8e3ff;

  .Hero {
    padding: 8rem 0;
    position: relative;

    @media only screen and (max-width: 575.98px) {
      padding: 8rem 0 6rem;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0;
      border-top: 100px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 100vw solid #3B1C4E;

      @media only screen and (max-width: 575.98px) {
        border-top: 50px solid transparent;
      }
    }

    section {
      padding-top: 0;
    }
  }

  h1 {
    font-family: 'NunitoSans';
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;
    background: linear-gradient(98.43deg, #EF3455 -30.22%, #C565F3 162.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media only screen and (max-width: 575.98px) {
      font-size: 36px;
    }
  }

  .GradientBg {
    background-image:
      linear-gradient(35deg,
        rgba(28, 12, 34, .7) 0%,
        rgba(34, 11, 50, .7) 24%,
        rgba(38, 10, 61, .7) 35%,
        rgba(40, 8, 68, .7) 43%,
        rgba(46, 7, 80, .7) 50%,
        rgba(49, 5, 87, .7) 57%,
        rgba(55, 5, 77, .7) 65%,
        rgba(65, 5, 72, .7) 72%,
        rgba(72, 5, 62, .7) 81%,
        rgba(77, 5, 49, .7) 90%,
        rgba(82, 5, 33, .7) 100%);
    box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
  }

  iframe {
    max-width: 100%;

    @media only screen and (max-width: 575.98px) {
      height: 220px;
    }
  }

  h3 {
    font-weight: 900;
  }

  h4 {
    font-weight: 700;

    br {
      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }

    @media only screen and (max-width: 575.98px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  p {
    margin: 0;
    font-size: 18px;

    strong {
      font-weight: 900;
      color: #ffffff;
    }
  }

  .Awards {
    h1 {
      color: #ffffff;
      background: transparent;
      -webkit-text-fill-color: #ffffff;
    }
  }

  .CaseStudy {
    padding: 4rem 0;
    background-color: #110716;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -100px;
      width: 100%;
      height: 0;
      border-top: 0px solid transparent;
      border-bottom: 100px solid transparent;
      border-left: 100vw solid #110716;

      @media only screen and (max-width: 575.98px) {
        bottom: -50px;
        border-bottom: 50px solid transparent;
      }
    }
  }

  .Results {
    text-align: center;

    .ResultItem {
      color: #ffffff;
      background: rgba(255, 255, 255, 0.02);
      border: 1px solid rgba(255, 255, 255, 0.16);
      border-radius: 16px;
      margin: 0 auto;
      padding: 35px 20px;
      width: 18rem;

      @media only screen and (max-width: 767.98px) {
        margin: 0 auto 2rem;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      height: 80px;
      width: 80px;
      display: flex;
      margin: 1rem auto 0;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      background: #6D2CB9;
      box-shadow: 0px 0px 26.2px rgba(109, 44, 185, 0.22);
      backdrop-filter: blur(100px);
      border-radius: 60px;
    }

    p {
      font-size: 20px;
      color: #ffffff;
    }

    span {
      font-size: 14px;
      color: #ffffff;
    }
  }

  .HowItWorks {
    background-image:
      linear-gradient(35deg,
      rgba(28, 12, 34, .7) 0%,
      rgba(34, 11, 50, .7) 24%,
      rgba(38, 10, 61, .7) 35%,
      rgba(40, 8, 68, .7) 43%,
      rgba(46, 7, 80, .7) 50%,
      rgba(49, 5, 87, .7) 57%,
      rgba(55, 5, 77, .7) 65%,
      rgba(65, 5, 72, .7) 72%,
      rgba(72, 5, 62, .7) 81%,
      rgba(77, 5, 49, .7) 90%,
      rgba(82, 5, 33, .7) 100%);
    box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
    color: #ffffff;
    padding: 6rem 0;

    .Step {
      text-align: center;
      margin-bottom: 2rem;
      height: 100%;
      padding: 1rem;

      .StepImage {
        height: 22rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto 1rem;
      }

      p {
        margin: 0;
        font-size: 18px;
      }
    }
  }

  .DidYouKnow {
    position: relative;
    padding-bottom: 8rem;

    @media only screen and (max-width: 575.98px) {
      padding: 8rem 0 4rem;
    }

    .Box {
      p {
        height: 4.5rem;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -101px;
      width: 100%;
      height: 0;
      border-top: 101px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 100vw solid #3B1C4E;

      @media only screen and (max-width: 575.98px) {
        top: -50px;
        border-top: 50px solid transparent;
      }
    }
  }

  .Blog {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 0;
      border-top: 100px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 100vw solid #110716;

      @media only screen and (max-width: 575.98px) {
        border-top: 50px solid transparent;
      }
    }

    section {
      background-color: #3B1C4E;

      @media only screen and (max-width: 575.98px) {
        padding: 4rem 0 6rem;
      }
    }
  }

  .Box {
    text-align: center;
    background: #190e2b;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    padding: 35px 25px;
    height: 100%;
    color: #f8e3ff;
  }
}
