.ResutlsFor {
  font-weight: 900;

  span {
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
    color: transparent;
  }
}
