@import 'bootstrap/scss/bootstrap-utilities';

.Privacy {
  @extend .mb-5;
  color: #f8e3ff;

  h2 {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 24px;
    text-align: left;
    display: inline;

    span {
      display: block;
      font-size: 14pt;
      font-weight: 300;
    }
  }

  h3 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 32px;
    text-align: left;
  }

  h4 {
    font-size: 22px;
    text-align: left;
  }

  p {
    margin-bottom: 16px;
  }

  a {
    text-decoration: underline;
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  ul, ol {
    margin-bottom: 36px;
    padding-left: 16px;

    li {
      margin-bottom: 9px;
    }
  }

  ol {
    list-style-type: decimal;
    padding-left: 40px;
  }
}
