.DeviceFingerprinting {
  color: #f8e3ff;

  h3 {
    font-weight: 900;
  }

  h4 {
    font-weight: 700;

    br {
      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }

    @media only screen and (max-width: 575.98px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .Hero {
    padding: 8rem 0;
    position: relative;

    @media only screen and (max-width: 575.98px) {
      padding: 8rem 0 4rem;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0;
      border-top: 100px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 100vw solid #3B1C4E;

      @media only screen and (max-width: 575.98px) {
        border-top: 50px solid transparent;
      }
    }

    section {
      padding-top: 0;
    }

    img {
      @media only screen and (max-width: 575.98px) {
        max-width: 100%;
      }
    }
  }

  .Awards {
    section {
      padding-bottom: 4rem;

      &:after {
        display: none;
      }
    }
  }

  .HowItWorks {
    section {
      padding: 8rem 0;
      position: relative;

      @media only screen and (max-width: 575.98px) {
        padding: 4rem 0 8rem;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        width: 100%;
        height: 0;
        border-top: 0px solid transparent;
        border-bottom: 100px solid transparent;
        border-left: 100vw solid #3B1C4E;

        @media only screen and (max-width: 575.98px) {
          border-bottom: 50px solid transparent;
        }
      }
    }
  }

  .Enhance {
    section {
      padding-top: 4rem;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -100px;
        width: 100%;
        height: 0;
        border-top: 100px solid transparent;
        border-bottom: 0px solid transparent;
        border-right: 100vw solid #3B1C4E;

        @media only screen and (max-width: 575.98px) {
          top: -50px;
          border-top: 50px solid transparent;
        }
      }
    }
  }

  .DidYouKnow {
    section {
      padding-bottom: 4rem;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        bottom: -100px;
        width: 100%;
        height: 0;
        border-top: 0px solid transparent;
        border-bottom: 100px solid transparent;
        border-left: 100vw solid#3B1C4E;

        @media only screen and (max-width: 575.98px) {
          bottom: -50px;
          border-bottom: 50px solid transparent;
        }
      }
    }
  }

  .Blog {
    section {
      padding-top: 8rem;
      padding-bottom: 0;

      &:after {
        display: none;
      }
    }
  }

  .Box {
    background: #190e2b;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    padding: 35px 25px;
    height: 100%;

    h4 {
      height: 60px;
    }

    p {
      margin-bottom: 0;
      font-size: 18px;
    }
  }

  .Polygon {
    align-items: center;
    background-color: #fff;
    background: transparent url('../components/icons/polygon.svg') no-repeat 50%;
    background-size: 100%;
    display: flex;
    height: 9rem;
    justify-content: center;
    margin: 0 auto;
    padding: .85rem;
    position: relative;
    transition: all .25s ease;
    width: 8rem;

    img {
      margin-top: -15px;
    }
  }

  .GradientBg {
    background-image: linear-gradient(35deg,
      rgba(28, 12, 34, .7) 0%,
      rgba(34, 11, 50, .7) 24%,
      rgba(38, 10, 61, .7) 35%,
      rgba(40, 8, 68, .7) 43%,
      rgba(46, 7, 80, .7) 50%,
      rgba(49, 5, 87, .7) 57%,
      rgba(55, 5, 77, .7) 65%,
      rgba(65, 5, 72, .7) 72%,
      rgba(72, 5, 62, .7) 81%,
      rgba(77, 5, 49, .7) 90%,
      rgba(82, 5, 33, .7) 100%);
    box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
  }
}
