header {
  @media screen and (max-width: 992px) {
    .container {
      display: block !important;

      .navbar-brand {
        display: inline-block;
        max-width: 15rem;
      }

      form {
        margin-top: 1rem;
      }
    }
  }
}
