@import 'bootstrap/scss/bootstrap-utilities';

.Tag {
  @extend .text-uppercase;
  @extend .text-nowrap;
  @extend .fw-bold;

  color: #49295f;
  text-decoration: none;

  &:hover {
    color: #49295f;
    text-decoration: underline;
  }
}
