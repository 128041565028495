@import 'bootstrap/scss/bootstrap-utilities';

@import '../../styles/effects.scss';

.Title {
  @extend .mx-auto;
  @extend .GradientTextColor;

  font-family: 'NunitoSans';
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;

  @media only screen and (max-width: 575.98px) {
    font-size: 32px;
  }
}

.Gradient {
  @extend .mx-auto;
  @extend .GradientTextColor;

  color: #533368;
  font-family: 'Karla', sans-serif;
  font-size: 40px;
  font-weight: 800;
  max-width: 600px;
}
