.Steps {
  h4 {
    @media only screen and (max-width: 575.98px) {
      text-align: center;
    }
  }

  p {
    font-size: 18px;
    line-height: 27px;
    color: #f8e3ff;

    @media only screen and (max-width: 575.98px) {
      text-align: center;
    }
  }

  img {
    max-width: 75%;
    max-height: 25rem;
    margin: 0 auto;
  }
}
