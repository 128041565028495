.CaseStudy {
  padding-top: 3rem;

  @media only screen and (max-width: 767.98px) {
    padding-top: 2rem;
  }

  .GradientBg {
    background-image:
      linear-gradient(35deg,
        rgba(28, 12, 34, .7) 0%,
        rgba(34, 11, 50, .7) 24%,
        rgba(38, 10, 61, .7) 35%,
        rgba(40, 8, 68, .7) 43%,
        rgba(46, 7, 80, .7) 50%,
        rgba(49, 5, 87, .7) 57%,
        rgba(55, 5, 77, .7) 65%,
        rgba(65, 5, 72, .7) 72%,
        rgba(72, 5, 62, .7) 81%,
        rgba(77, 5, 49, .7) 90%,
        rgba(82, 5, 33, .7) 100%);
    box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
  }

  .Intro {
    .Card {
      background: #190e2b;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 14rem;
      margin: 0 auto 3rem;
      padding: 2.5rem 1.5rem;
      border-radius: 3px;

      img {
        margin: 0;
      }
    }

    p {
      text-align: center;
      color: #ffffff;
      font-size: 26px;

      strong {
        color: #EF3355;
      }

      a {
        color: #ffffff;
        font-weight: normal;
      }
    }
  }

  .Image {
    @media only screen and (max-width: 767.98px) {
      max-width: 85%;
      margin-bottom: 2.5rem;
    }
  }

  .Title {
    font-size: 54px;
    font-weight: 800;
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
    color: transparent;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 3rem 0;
  }

  .Reverse {
    div {
      &:first-child {
        @media only screen and (max-width: 767.98px) {
          order: 2;
        }
      }

      &:last-child {
        @media only screen and (max-width: 767.98px) {
          order: 1;
        }
      }
    }
  }

  .DottedBg {
    padding: 1rem 0 3rem;
    background: #281236 url('./bg-dots.png') no-repeat bottom center;
    background-size: cover;
    box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
  }

  .Element {
    iframe {
      @media only screen and (max-width: 767.98px) {
        width: 100%;
        height: 13rem;
      }
    }

    h2 {
      font-size: 54px;
      font-weight: 800;
      background-clip: text;
      -webkit-background-clip: text;
      background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
      color: transparent;
      -webkit-text-fill-color: transparent;
    }

    p {
      font-size: 20px;
      color: #ffffff;

      a {
        color: #ffffff;
        font-weight: normal;
      }
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .Steps {
    display: flex;

    @media only screen and (max-width: 767.98px) {
      display: block;
    }

    .Step {
      width: 22%;
      text-align: center;
      background: #190e2b;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
      border-radius: 6px;
      margin: 0 auto 3rem;
      padding: 1.5rem;
      border-radius: 3px;

      @media only screen and (max-width: 767.98px) {
        width: 70%
      }

      h5 {
        font-size: 20px;
        color: #EF3355;
        font-weight: 700;
      }

      p {
        color: #ffffff;
        margin: 0;
      }

      hr {
        background-color: #ffffff;
      }

      .Blue {
        font-weight: 300;
        color: #6b87eb;
        text-shadow:
          0 0 1px #4e6fe4,
          0 0 3px #4e6fe4,
          0 0 9px #4e6fe4,
      }

      .Red {
        font-weight: 300;
        color: #EF3355;
        text-shadow:
          0 0 1px #EF3355,
          0 0 3px #EF3355,
          0 0 9px #EF3355,
      }
    }
  }

  .Results {
    text-align: center;

    .ResultItem {
      background: #190e2b;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 20rem;
      padding: 1.5rem .5rem;
      margin: 0 3rem;
      border-radius: 3px;

      @media only screen and (max-width: 767.98px) {
        margin: 0 auto 2rem;
      }
    }

    h2 {
      font-size: 54px;
      font-weight: 800;
      background-clip: text;
      -webkit-background-clip: text;
      background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
      color: transparent;
      -webkit-text-fill-color: transparent;
    }

    h3 {
      font-size: 46px;
      font-weight: 800;
      color: #EF3355;
    }

    p {
      font-size: 20px;
      color: #ffffff;
    }

    span {
      font-size: 14px;
      color: #ffffff;
    }
  }

  .BlogSection {
    & > section {
      &:after {
        display: none !important;
      }
    }
  }
}
