.Hexagons {
  border: 1px solid transparent;
  left: 0;
  margin: 300px 0 280px 0;
  position: relative;
  top: 0;

  @media only screen and (max-width: 1399px) {
    margin: 224px 0;
    transform: scale(0.85);
  }

  @media only screen and (max-width: 1199px) {
    margin: 190px 0;
    transform: scale(0.725);
  }

  @media only screen and (max-width: 991px) {
    margin: 390px 0 340px 0;
  }

  @media only screen and (max-width: 575px) {
    margin: 400px 0 350px;
    transform: scale(0.7);
  }
}

.BaseHexagon {
  position: absolute;

  left: calc(50% - 108px);
  top: -108px;

  h3 {
    white-space: pre-line;
  }
}

.Hexagon1 {
  @extend .BaseHexagon;
  transform: translate(-480px, 16px);

  @media only screen and (max-width: 991px) {
    transform: translate(-160px, -458px);
  }
}

.Hexagon2 {
  @extend .BaseHexagon;
  transform: translate(-480px, 200px);

  @media only screen and (max-width: 991px) {
    transform: translate(-160px, -276px);
  }
}

.Hexagon3 {
  @extend .BaseHexagon;
  transform: translate(-160px, 200px);

  @media only screen and (max-width: 991px) {
    transform: translate(0px, -368px);
  }
}

.Hexagon4 {
  @extend .BaseHexagon;
  transform: translate(-320px, 108px);

  @media only screen and (max-width: 991px) {
    transform: translate(160px, -276px);
  }
}

.Hexagon5 {
  @extend .BaseHexagon;
  transform: translate(480px, -206px);

  @media only screen and (max-width: 991px) {
    transform: translate(0, 60px);
  }

  @media only screen and (max-width: 575.98px) {
    transform: translate(0px, 143px);
  }
}

.Hexagon6 {
  @extend .BaseHexagon;
  transform: translate(161px, -18px);

  @media only screen and (max-width: 991px) {
    transform: translate(-160px, 306px);
  }

  @media only screen and (max-width: 575.98px) {
    transform: translate(-160px, 236px);
  }
}

.Hexagon7 {
  @extend .BaseHexagon;
  transform: translate(320px, -112px);

  @media only screen and (max-width: 991px) {
    transform: translate(0px, 398px);
  }

  @media only screen and (max-width: 575.98px) {
    transform: translate(0px, 328px);
  }
}

.Hexagon8 {
  @extend .BaseHexagon;
  transform: translate(480px, -18px);

  @media only screen and (max-width: 991px) {
    transform: translate(160px, 306px);
  }

  @media only screen and (max-width: 575.98px) {
    transform: translate(160px, 236px);
  }
}
