.Partners {
  margin: 3rem 0 0;
  padding-bottom: 2.75rem;
  transform: rotate(-3deg);

  @media only screen and (max-width: 1366px) {
    transform: rotate(-4.5deg);
  }

  @media only screen and (max-width: 575.98px) {
    margin: 1rem 0 0;
    padding-bottom: 2rem;
    transform: rotate(-6.5deg);
  }

  .SliderItem {
    margin: 0 2rem;

    &>div {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 80px;
      text-align: center;
    }

    img {
      display: block;
      max-width: 140px;
      max-height: 60px;

      @media only screen and (max-width: 767.98px) {
        max-width: 150px;
        max-height: 80px;
      }

      @media only screen and (max-width: 575.98px) {
        max-width: 100px;
        max-height: 40px;
      }
    }

    p {
      font-size: 15px;
      color: #d8d8d8;

      @media only screen and (max-width: 575.98px) {
        font-size: 14px;
      }
    }

    span {
      display: block;
      width: 100%;
      font-size: 14px;
      opacity: 0.75;
    }
  }
}
