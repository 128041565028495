@import url('https://fonts.googleapis.com/css2?family=Karla:wght@300;500;600;700;800&display=swap');

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~font-awesome/scss/font-awesome';

// Components
@import 'app/styles/components/components--main';
@import 'app/styles/components/components--buttons';
@import 'app/styles/components/components--custom-checkbox';
@import 'app/styles/components/components--fonts';

// Sectons
@import 'app/styles/sections/sections--quote';
