@import '~font-awesome/scss/font-awesome';

.Stack {
  @extend .fa-stack;

  &:hover {
    span {
      color: #49295f;
    }
  }
}

.Circle {
  @extend .fa;
  @extend .fa-circle;
  @extend .fa-stack-2x;

  color: #49295f44;
}

.Link {
  @extend .fa;
  @extend .fa-link;
  @extend .fa-stack-1x;
  @extend .fa-rotate-270;
  @extend .fa-inverse;
}

.Popover {
  * {
    background-color: #ffffff;
  }
}
