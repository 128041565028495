// NUNITO SANS

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/nunito-sans/NunitoSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/nunito-sans/NunitoSans-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/nunito-sans/NunitoSans-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/nunito-sans/NunitoSans-ExtraBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('../../fonts/nunito-sans/NunitoSans-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
}
