.BoxStyle {
  width: 100%;
  text-align: center;
  color: #ffffff;
  padding: 35px 25px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.Solution {
  position: relative;
  background-color: #110716;
  padding-top: 4rem;
  padding-bottom: 8rem;

  @media only screen and (max-width: 575.98px) {
    padding: 2rem 0 5rem;
  }

  h1 {
    font-family: 'NunitoSans';
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;
  }

  .LogoItem {
    @media screen and (max-width: 992px) {
      position: absolute;
      top: 40%;
    }

    @media screen and (max-width: 575.98px) {
      top: 50rem;
    }

    img {
      @media screen and (max-width: 992px) {
        display: block;
        max-width: 90%;
        margin: 0 auto;
      }
    }
  }

  .SolutionItems {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }

  .Item {
    height: 9rem;

    &:last-child {
      height: auto;
    }

    @media screen and (max-width: 992px) {
      height: auto;
    }

    @media screen and (max-width: 575.98px) {
      margin-bottom: 1rem;
    }

    .ItemImg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      margin: 0 auto .75rem;
      background: rgba(230, 62, 121, 0.3);
      box-shadow: 0px 0px 26.2px rgba(230, 62, 121, 0.2);
      backdrop-filter: blur(100px);
      border-radius: 60px;

      img {
        width: 65%;
      }
    }

    p {
      margin: 0;
    }
  }

  .Result {
    @media only screen and (max-width: 575.98px) {
      margin-top: 3rem;
    }
  }

  .Devices,
  .Data,
  .Result {
    .SolutionHead {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background: #3E0C2B;
      border: 1px solid rgba(0, 0, 0, 0.16);
      border-radius: 16px;
      padding: 1rem 0;
      text-transform: uppercase;
      font-weight: 200;
      margin-bottom: 1rem;

      h3 {
        margin-bottom: 0;
        font-size: 20px;
      }

      @media only screen and (max-width: 575.98px) {
        width: 100%;
        text-align: center;
      }
    }

    .SolutionContent {
      @extend .BoxStyle;
    }
  }

  .Devices {
    width: 25%;

    @media screen and (max-width: 992px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }

    h3 {
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }

    & + div {
      @media screen and (max-width: 992px) {
        transform: rotate(90deg);
        width: 100% !important;
        margin-left: -2px;
      }

      @media only screen and (max-width: 575.98px) {
        margin-top: 10px;
      }
    }
  }

  .Border {
    display: flex;
    justify-content: space-between;
    width: 62%;
    border: double 2px transparent;
    border-radius: 16px;
    background-image: linear-gradient(#110716, #110716),
      radial-gradient(circle at top,
        #EF3557,
        #EF3557,
        #EF3557,
        transparent,
        transparent);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    position: relative;

    @media screen and (max-width: 992px) {
      margin-top: 6rem;
      width: 100%;
    }

    @media only screen and (max-width: 575.98px) {
      flex-direction: column;
    }

    &:before {
      content: '';
      display: block;
      height: 45px;
      width: 2px;
      background: linear-gradient(to bottom,
            transparent 0%,
            #EF3557 75%);
      position: absolute;
      top: -46px;
      left: 49%;
    }

    .Data,
    .Result {
      width: 40%;

      @media screen and (max-width: 992px) {
        width: 40%;
      }

      @media only screen and (max-width: 575.98px) {
        width: 100%;
      }

      .Graphics {
        @media screen and (max-width: 992px) {
          width: 20% !important;
        }
      }
    }
  }

  .Graphics {
    width: 10% !important;
    height: 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    @media screen and (max-width: 575.98px) {
      transform: rotate(90deg);
      width: 100% !important;
      margin-top: 1rem;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 100vw solid #3B1C4E;

    @media only screen and (max-width: 575.98px) {
      border-top: 50px solid transparent;
    }
  }
}
