@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/bootstrap-utilities';

.GradientTextColor {
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.GradientButton {
  @extend .btn;
  @extend .btn-lg;
  @extend .rounded-pill;
  @extend .px-4;
  @extend .border-0;
  @extend .text-white;
  @extend .fw-bold;
  @extend .shadow-lg;

  background-color: #EF3355;
  background-image: linear-gradient(45deg, #EF3355, #C766F4, #EF3355, #C766F4);
  background-repeat: no-repeat;
  background-size: 300%;
  background-position: 0;
  transition-property: background-position, box-shadow;
  transition-timing-function: ease;
  transition-duration: .3s;
  font-size: 16px;

  &:hover {
    @extend .text-white;
    @extend .shadow;
    background-position: 100%;
    background-color: #0aa7e5;
    border-color: #0a9ed8;
  }
}
