@import 'styles/components/components--blogpost';
@import 'styles/components/components--blogheader';
@import 'styles/components/components--blogform';

h1 {
  font-family: 'Karla', sans-serif;
  font-size: 40px;
  font-weight: 800;
  background-clip: text;
  background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
  color: transparent;
}
