.ChromeExtension {
  color: #f8e3ff;
  position: relative;

  .Hero {
    padding: 8rem 0;
    position: relative;

    @media only screen and (max-width: 575.98px) {
      padding: 8rem 0 4rem;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0;
      border-top: 100px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 100vw solid #3B1C4E;

      @media only screen and (max-width: 575.98px) {
        border-top: 50px solid transparent;
      }
    }
  }


  .Awards {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -100px;
      width: 100%;
      height: 0;
      border-top: 0px solid transparent;
      border-bottom: 100px solid transparent;
      border-left: 100vw solid #3B1C4E;

      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }

    section {
      @media only screen and (max-width: 575.98px) {
        padding-bottom: 2rem;
      }

      &:after {
        display: none;
      }
    }
  }

  .Defend {
    padding: 10rem 0;
    position: relative;

    @media only screen and (max-width: 575.98px) {
      padding: 4rem 0 6rem;
    }

    p {
      margin-bottom: 10px;

      @media only screen and (max-width: 575.98px) {
        text-align: center;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -101px;
      width: 100%;
      height: 0;
      border-top: 101px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 100vw solid #3B1C4E;

      @media only screen and (max-width: 575.98px) {
        top: 0px;
        border-top: 0px solid transparent;
        border-bottom: 50px solid transparent;
        border-left: 100vw solid #3B1C4E;
      }
    }
  }

  .CaseStudy {
    padding: 4rem 0;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -101px;
      width: 100%;
      height: 0;
      border-top: 101px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 100vw solid #3B1C4E;

      @media only screen and (max-width: 575.98px) {
        top: -50px;
        border-top: 50px solid transparent;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -100px;
      width: 100%;
      height: 0;
      border-top: 0px solid transparent;
      border-bottom: 100px solid transparent;
      border-left: 100vw solid #3B1C4E;

      @media only screen and (max-width: 575.98px) {
        bottom: -50px;
        border-bottom: 50px solid transparent;
      }
    }
  }

  .Works {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -101px;
      width: 100%;
      height: 0;
      border-top: 101px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 100vw solid #3B1C4E;

      @media only screen and (max-width: 575.98px) {
        top: -50px;
        border-top: 50px solid transparent;
      }
    }
  }

  .DidYouKnow {
    position: relative;

    .Box {
      p {
        height: 4.5rem;
      }
    }
  }

  h1 {
    font-family: 'NunitoSans';
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;
    background: linear-gradient(98.43deg, #EF3455 -30.22%, #C565F3 162.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media only screen and (max-width: 575.98px) {
      font-size: 36px;
    }
  }

  iframe {
    max-width: 100%;

    @media only screen and (max-width: 575.98px) {
      height: 220px;
    }
  }

  h3 {
    font-weight: 900;
  }

  h4 {
    font-weight: 700;

    br {
      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }

    @media only screen and (max-width: 575.98px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  p {
    margin: 0;
    font-size: 18px;

    strong {
      font-weight: 900;
      color: #ffffff;
    }
  }

  .Results {
    text-align: center;

    .ResultItem {
      color: #ffffff;
      background: rgba(255, 255, 255, 0.02);
      border: 1px solid rgba(255, 255, 255, 0.16);
      border-radius: 16px;
      margin: 0 auto;
      padding: 35px 20px;
      width: 18rem;

      @media only screen and (max-width: 767.98px) {
        margin: 0 auto 2rem;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      height: 80px;
      width: 80px;
      display: flex;
      margin: 1rem auto 0;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      background: #6D2CB9;
      box-shadow: 0px 0px 26.2px rgba(109, 44, 185, 0.22);
      backdrop-filter: blur(100px);
      border-radius: 60px;
    }

    p {
      font-size: 20px;
      color: #ffffff;
    }

    span {
      font-size: 14px;
      color: #ffffff;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 100vw solid #3B1C4E;

    @media only screen and (max-width: 575.98px) {
      border-top: 50px solid transparent;
    }
  }

  .HowItWorks {
    padding: 8rem 0;
    background-image:
      linear-gradient(35deg,
        rgba(28, 12, 34, .7) 0%,
        rgba(34, 11, 50, .7) 24%,
        rgba(38, 10, 61, .7) 35%,
        rgba(40, 8, 68, .7) 43%,
        rgba(46, 7, 80, .7) 50%,
        rgba(49, 5, 87, .7) 57%,
        rgba(55, 5, 77, .7) 65%,
        rgba(65, 5, 72, .7) 72%,
        rgba(72, 5, 62, .7) 81%,
        rgba(77, 5, 49, .7) 90%,
        rgba(82, 5, 33, .7) 100%);
    box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
    color: #ffffff;

    @media only screen and (max-width: 575.98px) {
      padding: 6rem 0 4rem;
    }

    .Step {
      text-align: center;
      margin-bottom: 2rem;
      height: 100%;
      padding: 1rem;

      .StepImage {
        height: 22rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto 1rem;
      }

      p {
        margin: 0;
        font-size: 18px;
      }
    }
  }

  .Blog {
    position: relative;

    section {
      background-color: #3B1C4E;
      padding-bottom: 4rem;

      &:after {
        @media only screen and (max-width: 575.98px) {
          border-top: 50px solid transparent;
          border-bottom: 0px solid transparent;
          border-right: 100vw solid #110716;
        }
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -100px;
      width: 100%;
      height: 0;
      border-top: 0px solid transparent;
      border-bottom: 100px solid transparent;
      border-left: 100vw solid #3B1C4E;
      z-index: 1;

      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }
  }

  .Contact {
    section {
      padding-top: 8rem;
    }
  }

  .Box {
    padding: 24px 32px;
    height: 90%;
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 8px;
    margin-bottom: 25px;
    text-align: center;
    transition: all .25s ease;

    p {
      margin: 0;
      white-space: pre-line;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
      opacity: 0.7;
      transition: all .25s ease;

      @media only screen and (max-width: 1200px) {
        min-height: 60px;
      }

      @media only screen and (max-width: 575.98px) {
        font-size: 16px;
        min-height: auto;
      }
    }

    .BoxImage {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 1rem;
      padding: .15rem;
      position: relative;
      transition: all .25s ease;
      width: 50px;
      height: 50px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      filter: drop-shadow(0px 0px 26.2px rgba(230, 62, 121, 0.2));
      backdrop-filter: blur(100px);
      border-radius: 30px;

      img {
        display: block;
        width: 80%;
      }
    }

    &:hover {
      background: #3E0C2B;
      border: 1px solid #3E0C2B;
      box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.12);
      transition: all .25s ease;

      p {
        opacity: 1;
        transition: all .25s ease;
      }
    }
  }

  .GradientBg {
    background-image:
      linear-gradient(35deg,
        rgba(28, 12, 34, .7) 0%,
        rgba(34, 11, 50, .7) 24%,
        rgba(38, 10, 61, .7) 35%,
        rgba(40, 8, 68, .7) 43%,
        rgba(46, 7, 80, .7) 50%,
        rgba(49, 5, 87, .7) 57%,
        rgba(55, 5, 77, .7) 65%,
        rgba(65, 5, 72, .7) 72%,
        rgba(72, 5, 62, .7) 81%,
        rgba(77, 5, 49, .7) 90%,
        rgba(82, 5, 33, .7) 100%);
    box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
  }
}
