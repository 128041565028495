.Technology {
  margin: 5rem 0 4rem;

  h1 {
    font-family: 'NunitoSans';
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;
    background: linear-gradient(98.43deg, #EF3455 -30.22%, #C565F3 162.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  p {
    color: #ffffff;
    font-size: 20px;
    text-align: center;

    a {
      text-decoration: none;
      background-clip: text;
      background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
      color: transparent;
      opacity: 1;
    }
  }

  .TechnologyBox {
    display: flex;
    padding: 50px;
    background: #432555;
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 24px;
    margin-bottom: 1rem;

    @media only screen and (max-width: 991.98px) {
      display: block;
      text-align: center;
    }

    @media only screen and (max-width: 575.98px) {
      padding: 25px;
    }

    &:last-child {
      border-bottom: 0;
    }

    .TechnologyBadge {
      display: block;
      width: 12%;

      @media only screen and (max-width: 991.98px) {
        width: 100%;
        text-align: center;
      }

      span {
        background: #6D2CB9;
        box-shadow: 0px 0px 26.2px rgba(109, 44, 185, 0.22);
        border-radius: 60px;
        width: 95px;
        height: 95px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 991.98px) {
          margin: 0 auto;
        }

        img {
          display: block;
          max-width: 40%;
          max-height: 40%;
        }

        & + a {
          margin-top: 1rem;
          margin-left: 5px;
          text-decoration: none;
          color: #C565F3;
          opacity: 1;
        }
      }
    }

    .TechnologyText {
      width: 87%;

      @media only screen and (max-width: 991.98px) {
        width: 100%;
        padding-top: 2rem;
      }

      h3 {
        font-size: 22px;
        font-weight: 900;
        color: #f8e3ff;
        margin-bottom: 2rem;
      }

      p {
        color: #ffffff;
        font-size: 18px;
        text-align: left;

        @media only screen and (max-width: 575.98px) {
          text-align: center;
        }
      }

      a {
        text-decoration: none;
        background-clip: text;
        background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
        color: transparent;
        opacity: 1;
      }

      ul {
        padding: 0 0 0 1.5rem;
        margin: 2rem 0;

        li {
          color: #ffffff;
          list-style: none;
          position: relative;
          margin-bottom: 3px;

          &:before {
            content: '';
            background-image: url('../../images/list-item.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            width: 12px;
            height: 12px;
            display: block;
            position: absolute;
            left: -22px;
            top: 6px;
          }

          @media only screen and (max-width: 575.98px) {
            text-align: left;
            font-size: 18px;
          }
        }
      }
    }

    .OnlyDesktop {
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
    }

    .OnlyMobile {
      display: none;

      @media only screen and (max-width: 991.98px) {
        display: inline-block;
      }
    }
  }

  .HexaContainer {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 4rem;

    @media only screen and (max-width: 1399px) {
      width: 100%;
    }

    @media only screen and (max-width: 1220px) {
      padding: 0;
    }

    @media only screen and (max-width: 991.98px) {
      flex-direction: column;
      align-items: center;
    }

    @media only screen and (max-width: 575.98px) {
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: row;
    }

    .Hexa {
      width: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 3rem 0;
      transform: scale(0.9);

      @media only screen and (max-width: 1189.98px) {
        transform: scale(0.8);
      }

      @media only screen and (max-width: 575.98px) {
        transform: scale(1);
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 0;
      }

      &:first-child {
        @media only screen and (max-width: 991.98px) {
          margin-bottom: 9rem;
        }

        @media only screen and (max-width: 575.98px) {
          margin: 0;
        }
      }

      &:last-child {
        bottom: -68px;

        @media only screen and (max-width: 575.98px) {
          bottom: unset;
        }
      }

      .HexaItem {
        height: 175px;
        width: 185px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;

        @media only screen and (max-width: 575.98px) {
          position: static;
          width: auto;
          margin-bottom: 1rem;
        }

        .HexaBg {
          text-align: center;
        }

        .HexaImg {
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          width: auto;
          display: block;
          margin: 0 auto 20px;
          transform: scale(1.5);
        }

        p {
          color: #ffffff;
          margin: 0;
          font-size: 1rem;
          display: block;
        }

        &:nth-child(1) {
          top: -90px;
          left: -62px;
        }

        &:nth-child(2) {
          top: 43px;
          right: -163px;
        }

        &:nth-child(3) {
          bottom: -90px;
          left: -60px;
        }

        &:nth-child(4) {
          bottom: -90px;
          right: -36px;
        }

        &:nth-child(5) {
          top: -90px;
          right: -90px;
        }

        &:nth-child(6) {
          top: 43px;
          left: -160px;
        }
      }

      .HexaInner {
        height: 235px;
        width: 270px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent url('../../images/hexa-1.svg');
        background-position: center;
        background-size: 100%;

        @media only screen and (max-width: 575.98px) {
          background: none;
          width: auto;
          height: auto;
          display: flex;
        }

        h3 {
          color: #f8e3ff;
          font-size: 20px;
          font-weight: 700;
          margin: 0;
          padding: 0;
          text-align: center;
          max-width: 144px;

          @media only screen and (max-width: 575.98px) {
            font-size: 1.5rem;
          }
        }

        &:last-child {
          @media only screen and (max-width: 575.98px) {
            order: -1;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
