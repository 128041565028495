.Footer {
  background-color: #3B1C4E;
  color: #fff;
  font-size: 16px;
  margin-top: auto;

  [class=col-lg-4] {
    border-right: 1px solid rgba(255, 255, 255, .25);

    @media screen and (max-width: 991px) {
      border-bottom: 1px solid rgba(255, 255, 255, .25);
      border-right: 0;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }

    &:last-child {
      border-right: 0;
      border-bottom: 0;
    }
  }

  a {
    font-weight: normal;
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      padding-bottom: .25rem;
    }
  }

  span {
    display: inline-block;
  }

  h5 {
    font-size: 18px !important;
    font-weight: 900 !important;
    text-transform: uppercase;
  }

  .FooterItem, h5 {
    color: #f8e3ff;
    cursor: pointer;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-decoration: none;
    padding-bottom: .25rem;
    transition: all .25s ease;

    &:hover {
      background-clip: text;
      background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
      color: transparent;
      opacity: 1;
      text-decoration: none;
      transition: all .25s ease;
    }
  }

  .Social {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    padding: .5rem;
    background-color: rgba(152, 129, 223, .5);
    border-radius: 100%;
    margin-right: .5rem;
    transition: all .25s ease;

    &:hover {
      background-color: rgba(152, 129, 223, 1);
      transition: all .25s ease;
    }

    img {
      display: block;
      margin: 2px auto 0;
    }
  }
}
