.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;

    &:checked ~ .custom-control-label:before {
      color: #212529;
      border-color: #DC4BA1;
      background-color: #DC4BA1;
    }

    &:focus ~ .custom-control-label:before {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 .2rem rgba(27, 252, 190, .25);
    }

    &:focus:not(:checked) ~ .custom-control-label:before {
      border-color: #DC4BA1;
    }

    &:not(:disabled):active ~ .custom-control-label:before {
      color: #212529;
      background-color: #cbfef0;
      border-color: #cbfef0;
    }
  }

  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    color: #DC4BA1;
    vertical-align: top;
    cursor: pointer;

    &:before {
      pointer-events: none;
      background-color: #2c2c2e;
      border: 1px solid #DC4BA1;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    }

    &:after {
      background: no-repeat 50%/50% 50%;
    }

    &:before,
    &:after {
      position: absolute;
      top: .25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
    }
  }
}

.custom-checkbox {
  .custom-control-label:before {
    border-radius: .25rem;
  }
}
