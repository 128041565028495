.Pagination {
  .page-item {
    border: 0;
    margin: 0 0.25rem;
    font-weight: 700;

    &.active {
      a {
        color: #ffffff;
        background-color: #EF3355;
        background-image: linear-gradient(45deg, #EF3355, #C766F4, #EF3355, #C766F4);
        background-repeat: no-repeat;
        background-size: 300%;
        background-position: 0;
      }
    }

    a {
      border: 0;
      border-radius: 4px;
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .141176);
      color: #49295f;
      transition: all .25s ease;

      &:hover {
        background-color: #49295f;
        color: #ffffff;
        transition: all .25s ease;
      }
    }
  }
}
