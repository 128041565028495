@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/bootstrap-utilities';

.PostTile {
  @extend .card;
  @extend .h-100;

  border: 0;
  border-radius: 6px;
  background-color: #190e2b;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.141176);
  cursor: pointer;

  &:hover {
    h5 {
      background-clip: text;
      -webkit-background-clip: text;
      background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
      color: transparent;
    }

    img {
      transform: scale(1.1);
      transition: all 0.3s ease-in-out;
    }
  }
}

.TileTitle {
  color: inherit;
  display: inline-block;
  font-weight: 700;
  color: #f8e3ff;
}

.TileImg {
  overflow: hidden;

  img {
    height: 180px;
    object-fit: cover;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
}

.TileText {
  @extend .card-text;
  @extend .mt-2;

  p {
    color: #f8e3ff;
    display: -webkit-box;
    margin: 0;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}
