html,
body,
#root {
  min-height: 100vh;
}

html, main {
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  font-family: 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #3b1c4e;
}

main {
  @media screen and (max-width: 992px) {
    width: 100%;
    overflow-x: hidden;
  }
}

section {
  padding-top: 5rem;

  @media only screen and (max-width: 575.98px) {
    padding-top: 2rem;
  }
}

.mainlink {
  text-decoration: none;
  background-clip: text;
  background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
  color: transparent;
  opacity: 1;
  text-decoration: none;
  transition: all .25s ease;

  &:hover {
    background-clip: text;
    background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
    color: transparent;
    opacity: .75;
  }
}
