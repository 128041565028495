@import '../../styles/effects/gradient-button';

.Navbar {
  padding: 25px 0;
  z-index: 200;
  color: #442458;
  backdrop-filter: blur(4px);
  background-color: rgba(46, 21, 61, .8);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transition: padding 0.3s ease;

  &.scrolled {
    padding: 10px 0;
  }

  .logoSmall {
    width: 180px;
    margin-right: 60px;
    transition: width 0.3s ease;
  }

  @media screen and (max-width: 1055px) {
    padding-left: 80px;
  }

  @media screen and (max-width: 992px) {
    padding-left: 0;
  }

  li {
    list-style: none;
  }

  ul li ul li {
    a {
      color: #f8e3ff !important;
    }
  }

  .Dropdown {
    position: relative;
    padding: 8px 16px;

    ul {
      display: none;
      position: absolute;
      backdrop-filter: blur(4px);
      background-color: rgba(46, 21, 61, .8);
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      width: 300%;
      padding: 5px 10px;
      margin: 0;

      li {
        list-style: none;

        a {
          padding: 5px 0;
          color: #f8e3ff;
          cursor: pointer;

          &:hover {
            background-clip: text;
            background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
            color: transparent;
            opacity: 1;
            text-decoration: none;
            transition: all .25s ease;
          }
        }
      }
    }

    &:hover {
      ul {
        display: block;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .Logo {
    cursor: pointer;
    padding: 0;
    margin-right: 150px;

    @media screen and (max-width: 576px) {
      margin-right: 0;
    }
  }

  .menuBtn {
    color: #ffffff;
    opacity: 0.7;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    cursor: pointer;
    display: block;
    text-decoration: none;
    transition: all .25s ease;

    &:hover {
      color: #ffffff;
      opacity: 1;
      text-decoration: none;
      transition: all .25s ease;
    }
  }

  .navToggler {
    background: transparent;
    border: 0;
    border-radius: 4px;

    span {
      display: block;
      position: relative;
      background-image: none;
      width: 30px;
      height: 3px;
      background-color: silver;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 30px;
        height: 3px;
        background-color: silver;
        position: absolute;
      }

      &:before {
        top: -9px;
      }

      &:after {
        bottom: -9px;
      }
    }
  }
}
