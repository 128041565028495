.AwardSlider {
  img {
    width: 100%;
    max-width: 170px;
    display: block;
    margin: 1rem 0;

    @media only screen and (max-width: 575.98px) {
      max-width: 140px;
    }
  }

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
    min-height: 84px;
    padding: 0 12px;

    @media only screen and (max-width: 575.98px) {
      min-height: 60px;
    }
  }

  .slick-slide {
    padding: 0 20px;
    & > div {
      background: #432555;
      border: 1px solid rgba(255, 255, 255, 0.16);
      border-radius: 24px;
    }
  }

  .slick-dots {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    bottom: -3rem;

    @media only screen and (max-width: 575.98px) {
      bottom: -2rem;
      width: 94%;
    }

    li {
      width: 8px;
      height: 8px;
      border-radius: 100px;
      margin: 0 8px;
      transition: width .2s ease;
      background: #DA4DA8;

      &.slick-active {
        width: 45px;
        background: #C665F4;
      }

      button {
        &:before {
          display: none;
        }
      }
    }
  }
}
