.SearchBar {
  color: #ffffff;
  padding-left: 12px;
  padding-right: 12px;
  z-index: 100;

  [class=col] {
    background: #442458;
    width: calc(100% - 55px);
  }

  [class=col-1] {
    background: #442458;
    border-left: 1px solid white;
    text-align: center;
    width: 55px;

    i {
      margin-top: 11px;
      margin-left: -2px;

      @media only screen and (max-width: 767.98px) {
        margin-top: 28px;
      }
    }

    .DropDown {
      background: transparent;
      border: 0;
      color: white;
      width: 30px;
      height: 50px;
      padding: 0;
    }
  }
}

.TagsClosed {
  max-height: 30px;
  overflow: hidden;
}
