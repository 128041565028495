.quote {
  position: relative;
  padding-bottom: 8rem;

  h1 {
    font-family: 'NunitoSans';
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;
    text-align: center;
    color: #ffffff;

    @media only screen and (max-width: 575.98px) {
      font-size: 36px;
    }
  }

  .slider {
    padding: 12px 0;

    &__inner {
      max-width: 100% !important;
      padding: 42px 23px;
      text-align: center;
      width: 400px !important;
      margin: 0 auto;
      position: relative;
      background: #432555;
      border: 1px solid rgba(255, 255, 255, 0.16);
      border-radius: 24px;

      @media only screen and (max-width: 575.98px) {
        margin: 2rem auto 0;
      }

      &--image {
        width: 94px;
        height: 94px;
        margin: 0 auto;
        border-radius: 100%;
        text-align: center;
        transform: scale(1.3);
        padding: 1em;

        img {
          width: 100%;
          display: block;
          margin: 0 auto;
          border-radius: 100%;
          position: relative;
        }
      }

      &--text {
        z-index: 1;

        h5 {
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          text-align: center;
          color: #ffffff;

          & + p {
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: #ffffff;
            opacity: 0.7;
          }
        }
      }

      .title-style {
        height: 100px;
        color: #f8e3ff;
      }

      .text-style {
        height: 240px;
        color: #f8e3ff;

        p {
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          text-align: center;
          color: #ffffff;
          opacity: 0.7;
        }
      }

      &:hover {
        background: #5B2E74;
        border-radius: 24px;
        border: 1px solid transparent;
      }
    }

    .slick-list {
      padding: 0 0 1.25rem;

      .slick-track {
        display: flex !important;
        flex-wrap: wrap;

        .slick-slide {
          height: auto;

          &>div {
            height: 100%;
            text-align: center;
          }
        }
      }
    }

    .slick-dots {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        margin: 0 8px;
        transition: width .2s ease;
        background: #DA4DA8;

        &.slick-active {
          width: 45px;
          background: #C665F4;
        }

        button {
          &:before {
            display: none;
          }
        }
      }
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 100vw solid #110716;

    @media only screen and (max-width: 575.98px) {
      border-top: 50px solid transparent;
    }
  }
}
