@import 'bootstrap/scss/bootstrap-utilities';

.Post {
  font-size: 1.125rem;

  h1 {
    font-size: 3rem;
    font-weight: 900;
    background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    margin-bottom: 1.25rem;

    @media screen and (max-width: 992px) {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #f8e3ff;
  }

  h3 {
    font-size: 2.15rem;
    font-weight: 700;
    color: #f8e3ff;
  }

  h4 {
    font-size: 1.85rem;
    font-weight: 700;
    color: #f8e3ff;
  }

  p {
    margin: 1rem 0;
    color: #f8e3ff;
  }

  ul {
    li {
      color: #f8e3ff;
    }
  }

  iframe {
    max-width: 100%;
  }

  figure {
    margin: 2.5rem 0;
    text-align: center;
  }

  figcaption {
    text-align: center;
    font-size: small;
    color: gray;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  b, strong {
    font-weight: 900;
  }

  a {
    color: #EB3865;

    &:hover {
      color: #EB3865;
      text-underline-offset: 3px;
    }
  }

  [class~=wp-block-buttons] {
    margin: 3rem 0;
    text-align: center;
  }
}

.PostDots {
  @extend .w-100;
  @extend .me-2;

  background: transparent;
  background-image: radial-gradient(#1cb8dc 2px, transparent 0);
  background-size: 20px 20px;
  background-position: center center;
  background-repeat: repeat-x;
}

.PostRow {
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-between;
  @extend .mb-3;
  color: #f8e3ff;
}
