.BackToLink {
  background: transparent;
  border: 0;
  color: #f8e3ff;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;

  svg {
    display: inline-block;
    margin-right: 9px;
    transition: all .25s ease;
  }

  &:hover {
    color: #ffffff;

    svg {
      transform: translate(-8px);
      transition: all .25s ease;
    }
  }
}
