.NavbarNoBg {
  transition: all 0.3s ease-in-out;
}

.NavbarBg {
  backdrop-filter: blur(4px);
  background: hsla(0,0%,100%,.8);
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out;
}

.ContactBtn {
  opacity: 0.5;
}

.BlogButton {
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-image .25s ease;

  &:hover {
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(340deg, #EF3355 30%, #C766F4 73%);
    color: transparent;
    -webkit-text-fill-color: transparent;
    transition: all .25s ease;
  }
}

.Navbar {
  padding: 12px 0;
  z-index: 200;
  color: #442458;
  backdrop-filter: blur(4px);
  background-color: rgba(46, 21, 61, .8);
  box-shadow: 0 0 100px hsla(0, 0%, 0%, .3);

  a {
    span {
      color: #f8e3ff;
      cursor: pointer;
      display: block;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      text-decoration: none;
      text-transform: uppercase;
      transition: all .25s ease;

      &:hover {
        background-clip: text;
        background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
        color: transparent;
        opacity: 1;
        text-decoration: none;
        transition: all .25s ease;
      }
    }
  }

  @media screen and (max-width: 1055px) {
    padding-left: 80px;
  }

  @media screen and (max-width: 992px) {
    padding-left: 0;
  }

  .navToggler {
    background: #fff;
    border: 0;
    border-radius: 4px;

    span {
      display: block;
      position: relative;
      background-image: none;
      width: 30px;
      height: 3px;
      background-color: silver;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 30px;
        height: 3px;
        background-color: silver;
        position: absolute;
      }

      &:before {
        top: -9px;
      }

      &:after {
        bottom: -9px;
      }
    }
  }
}

.ProductMenu {
  h3 {
    color: #222222;
  }

  li {
    a {
      display: block;
      width: 100%;
    }

    img {
      display: block;
      height: 40px;
    }
  }
}
