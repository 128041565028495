@import './HexagonHover.module.scss';

.ClipPath {
  clip-path: polygon(0% 50%, 25% 6.7%, 75% 6.7%, 100% 50%, 75% 93.3%, 25% 93.3%);
  height: 206px;
  width: 206px;
}

.Hexagon {
  text-decoration: none;
  cursor: pointer;

  .hexa-border {
    @extend .ClipPath;

    background: #e63e79;
    display: flex;
    justify-content: center;
    align-items: center;

    .hexa-bg {
      @extend .ClipPath;

      background: #190e2b;
      height: calc(100% - 6px);
      width: calc(100% - 6px);

      display: flex;
      justify-content: center;
      align-items: center;

      .hexa-shadow {
        height: calc(100% - 6px);
        width: calc(100% - 6px);

        display: flex;
        justify-content: center;
        align-items: center;

        .hexa-selector {
          @extend .ClipPath;
          transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

          background: transparent;
          height: inherit;
          width: inherit;

          padding: 1.5rem 0;

          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          text-align: center;

          h3 {
            color: #f8e3ff;
            font-size: 20px;
            font-weight: 700;
            margin: 0;
            padding: 0;
            text-align: center;
            max-width: 144px;

            @media only screen and (max-width: 575.98px) {
              font-size: 21px;
            }
          }

          img {
            margin: 0;
            padding: 0;
            height: 19px;
          }
        }
      }
    }
  }

  &:hover {
    @extend .HexagonHover;
  }
}
