.Avatar {
  border-radius: 100%;
  margin-right: 1rem;
  width: 2rem;
}

.Letter {
  background-color: #271135;
  background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
  color: #ffffff;
  margin: 0 0.75rem 0 0 !important;
  padding: 0 !important;
  display: inline-block;
  font-size: 18pt !important;
  font-weight: 900;
  line-height: 20pt;
  height: 2rem;
  width: 2rem;
  text-align: center;
  border-radius: 100%;
}
