.Awards {
  position: relative;
  padding-bottom: 7rem;

  @media only screen and (max-width: 575.98px) {
    padding-top: 2rem;
  }

  h1 {
    color: #ffffff;
    font-family: 'NunitoSans';
    font-weight: 700;
    font-style: normal;
    font-size: 48px;
    line-height: 130%;

    @media only screen and (max-width: 767.98px) {
      font-size: 36px;
    }
  }

  .Intro {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #f8e3ff;

    @media only screen and (max-width: 575.98px) {
      font-size: 20px;
      margin-bottom: 3rem;

      br {
        display: none;
      }
    }
  }

  .AwardInner {
    min-height: 13rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 575.98px) {
      min-height: 9rem;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 100vw solid #110716;

    @media only screen and (max-width: 575.98px) {
      border-top: 50px solid transparent;
    }
  }
}
