.Separator {
  height: 400px;
  position: relative;
  text-align: center;
  margin-top: 1rem;

  @media only screen and (max-width: 991px) {
    height: 200px;
  }

  @media only screen and (max-width: 575.98px) {
    display: none;
  }
}

.LineStyleDefault {
  border-width: 1px;
  display: inline-block;
  height: 100%;
  width: 0px;
}

.LineStyle1 {
  @extend .LineStyleDefault;
  border-color: #ee3774;
  border-style: solid;
}

.LineStyle2 {
  @extend .LineStyleDefault;
  border-color: #54434d;
  border-style: dashed;
}

.LineStyle3 {
  @extend .LineStyleDefault;
  border-color: #ee3774;
  border-style: dashed;
}

.HeadStyleDefault {
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  left: calc(50% - 5px);
  top: calc(0% - 5px);
  height: 10px;
  width: 10px;
}

.HeadStyle1 {
  @extend .HeadStyleDefault;
  background-color: #ee3774;
  border: 0;
  box-shadow: 0 0 10px #eb7ba0;
}

.HeadStyle2 {
  @extend .HeadStyleDefault;
  border-color: #54434d;
}

.HeadStyle3 {
  @extend .HeadStyleDefault;
  border-color: #ee3774;
}

.TailStyleDefault {
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  left: calc(50% - 5px);
  bottom: calc(0% - 5px);
  height: 10px;
  width: 10px;
}

.TailStyle1 {
  @extend .TailStyleDefault;
  border-color: #ee3774;
}

.TailStyle2 {
  @extend .TailStyleDefault;
  border-color: #54434d;
}

.TailStyle3 {
  @extend .TailStyleDefault;
  background-color: #ee3774;
  border: 0;
  box-shadow: 0 0 10px #eb7ba0;
}
