@import '../../styles/effects/gradient-text';
@import '../../styles/effects/gradient-button';

.ChromeExtension {
  padding: 8rem 0;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -101px;
    width: 100%;
    height: 0;
    border-top: 101px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 100vw solid #3B1C4E;

    @media only screen and (max-width: 575.98px) {
      top: -50px;
      border-top: 50px solid transparent;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -100px;
    width: 100%;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 100px solid transparent;
    border-left: 100vw solid #3B1C4E;

    @media only screen and (max-width: 575.98px) {
      bottom: -50px;
      border-bottom: 50px solid transparent;
    }
  }


  h3 {
    @extend .GradientText;
  }

  h4 {
    color: #ffffff;
    margin: 2rem 0;
  }

  .ChromeExtensionForm {
    background: #432555;
    border: 1px solid rgba(255, 255, 255, 0.16);
    padding: 30px 40px 10px;
    box-shadow: 4px 4px 60px 30px rgba(25, 15, 43, .075);;
    border-radius: 7px;
  }

  fieldset {
    text-align: left;
  }

  input {
    background: #432555;
    box-shadow: 4px 4px 60px 30px rgba(25, 15, 43, .075);;
    border: 1px solid #e6e6e6;
    border-radius: .25rem;
    color: #fff;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    height: calc(1.5em + 1.6rem + 2px);
    line-height: calc(1rem + 10px);
    line-height: 1.5;
    padding: .8rem 1rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-bottom: 1rem;
  }

  button {
    display: block;
    margin: 2rem auto;
    text-transform: none;
    font-size: 16px;
    line-height: 19px;
  }
}

.HowItWorks {
  padding-top: 8rem;
  background-image:
    linear-gradient(35deg,
      rgba(28, 12, 34, .7) 0%,
      rgba(34, 11, 50, .7) 24%,
      rgba(38, 10, 61, .7) 35%,
      rgba(40, 8, 68, .7) 43%,
      rgba(46, 7, 80, .7) 50%,
      rgba(49, 5, 87, .7) 57%,
      rgba(55, 5, 77, .7) 65%,
      rgba(65, 5, 72, .7) 72%,
      rgba(72, 5, 62, .7) 81%,
      rgba(77, 5, 49, .7) 90%,
      rgba(82, 5, 33, .7) 100%);
  box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
  color: #ffffff;

  @media only screen and (max-width: 575.98px) {
    padding: 6rem 0 4rem;
  }

  &:after {
    content: '';
    display: block;
    bottom: 0;
    width: 100%;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 100vw solid #3B1C4E;

    @media only screen and (max-width: 575.98px) {
      border-top: 50px solid transparent;
    }
  }

  &:last-child::before {
    margin-bottom: 8rem;
  }

  .Step {
    text-align: center;
    margin-bottom: 2rem;
    height: 100%;
    padding: 1rem;

    .StepImage {
      height: 22rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto 1rem;
    }

    p {
      margin: 0;
      font-size: 18px;
    }
  }
}
