.btn {
  padding: 16px 30px;
  background-color: #EF3355;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .141176);
  background-image: linear-gradient(45deg, #EF3355, #C766F4, #EF3355, #C766F4);
  background-repeat: no-repeat;
  background-size: 300%;
  background-position: 0;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, .141176);
  border-radius: 999px !important;
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $white;
  transition: all .3s ease;

  &:hover {
    background-position: 100%;
    color: #FFFFFF !important;
    box-shadow: 0 4px 8px 0 rgba(113, 4, 153, 0.28) !important;
  }

  &:active {
    box-shadow: none;
    color: rgba(255, 255, 255, .75);
    transition-duration: .1s;
    transform: scale(0.99) translateY(1px);
    border: 0;
  }
}
