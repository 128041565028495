.FeaturedIn {
  background-color: #110716;
  margin: 0 auto;

  h1 {
    font-family: 'NunitoSans';
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;
  }

  .FeaturedContent {
    max-width: 1140px;
    margin: 0 auto;
  }

  .FeaturedItem {
    width: 240px;
    display: block;

    @media only screen and (max-width: 575.98px) {
      width: 50%;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 185px;
    height: 75px;
    margin: 1rem;
    transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    @media only screen and (max-width: 991.98px) {
      width: 75%;
      margin: 2rem 1rem;
    }

    @media only screen and (max-width: 575.98px) {
      margin: 1rem;
    }

    img {
      display: block;
      width: 100%;

      &:first-child {
        position: absolute;
        display: block;
        z-index: 2;
      }

      &:last-child {
        position: absolute;
        display: none;
        z-index: 1;
      }
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.15);
      transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

      img:frist-child {
        display: none;
        z-index: 1;
      }

      img:last-child {
        display: block;
        z-index: 2;
      }
    }
  }
}
