.Tag {
  background-color: #281236;
  color: white;
  font-family: 'Karla', sans-serif;
  font-size: 13px;
  font-weight: 700;

  &:hover {
    color: white;
    background-color: #A53A82;;
  }
}
