.UseCases {
  margin-top: 4rem;

  .Box {
    color: #f8e3ff;
    background: #190e2b;
    box-shadow: 0 0 20px rgba(0, 0, 0, .3);
    border-radius: 6px;
    width: 60%;
    margin: 0 auto 4rem;
    padding: 35px;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    h2 {
      font-size: 24px;
      font-weight: 900;
    }
  }
}

.ZeroTrustContainer {
  &>section {
    &:after {
      display: none !important;
    }
  }
}
