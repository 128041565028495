.Video {
  width: 100%;
  height: 545px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 16px;
  overflow: hidden;

  @media screen and (max-width: 1399px) {
    width: 100%;
    height: 475px;
  }

  @media screen and (max-width: 1199px) {
    height: 98%;
  }

  @media screen and (max-width: 991px) {
    display: block;
    margin: 0 auto 5rem;
    height: 150%;
  }

  @media only screen and (max-width: 575.98px) {
    margin: 0 auto;
  }
}
