@import '../../styles/effects.scss';

.Top {
  background-color: #190e2b;
  margin-bottom: 4rem;
  height: 60vh;
  overflow: hidden;

  @media only screen and (max-width: 991.98px) {
    height: auto;
  }
}

.OurTeam {
  margin-top: 1rem;

  @media only screen and (max-width: 575.98px) {
    margin-top: 0;
    padding-top: 5rem;
  }

  .Intro {
    padding: 3rem 3rem 2rem 12%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 575.98px) {
      text-align: center;
    }

    p {
      font-size: 18px;
      color: #f8e3ff;
    }
  }

  .MainImg {
    width: 100%;

    img {
      display: block;
      width: 100%;
      height: 140%;
      object-fit: cover;
    }
  }

  .Title {
    @extend .GradientTextColor;
    font-family: 'NunitoSans';
    font-weight: 800;
    font-size: 42px;
    text-transform: uppercase;
    text-align: left !important;

    @media only screen and (max-width: 575.98px) {
      text-align: center !important;
    }
  }

  p {
    a {
      text-decoration: none;
      background-clip: text;
      background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
      color: transparent;
      opacity: 1;
    }
  }
}

.TeamGoals {
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;

  @media only screen and (max-width: 575.98px) {
    display: block;
  }

  .Card {
    color: #f8e3ff;
    text-align: center;
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(1, 1, 1, 0.32);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border-radius: 16px;
    overflow: hidden;
    font-size: 18px;
    width: 20%;

    @media only screen and (max-width: 575.98px) {
      width: 100%;
      margin-bottom: 1rem;
      text-align: center;
    }

    .CardHead {
      padding: 1rem 1rem .5rem;
      border-bottom: 1px solid rgba(255, 255, 255, .15);
    }

    .CardContent {
      padding: .5rem 1rem 1rem;
    }

    h5 {
      color: #C565F3;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 21px;

      @media only screen and (max-width: 575.98px) {
        br {
          display: none;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      list-style: none;
    }
  }
}

.Founders {
  .Title {
    text-align: center !important;
  }

  .Founder {
    background: #432555;
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 24px;
    overflow: hidden;

    @media only screen and (max-width: 991.98px) {
      margin-bottom: 2rem;
    }

    .FounderData {
      padding: 1.5rem 2rem;
      min-height: 10rem;
    }

    .FounderName {
      font-weight: 900;
      color: #f8e3ff;
      font-size: 26px;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .FounderPosition {
      background-clip: text;
      background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
      color: transparent;
      opacity: 1;
      font-weight: 700;
      font-size: 18px;
    }

    .FounderContact {
      display: flex;
      justify-content: flex-end;

      a {
        margin-left: 5px;
      }
    }

    img {
      display: block;
      width: 100%;
    }
  }
}

.Featured {
  margin: 40px 0px;
  padding: 40px 0px;
  border-top: solid 1px #cacaca;
  border-bottom: solid 1px #cacaca;

  a {
    display: inline-block;
    position: relative;
    width: 185px;
    height: 75px;

    img {
      &:first-child {
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        z-index: 2;
      }

      &:last-child {
        position: absolute;
        top: 0px;
        left: 0px;
        display: none;
        z-index: 1;
      }
    }

    &:hover {
      img:frist-child {
        display: none;
        z-index: 1;
      }

      img:last-child {
        display: block;
        z-index: 2;
      }
    }
  }
}
