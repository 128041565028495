.Contact {
  background-color: #110716;
  position: relative;
  padding-bottom: 7rem;

  h1 {
    font-family: 'NunitoSans';
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;
  }

  .ContactInner {
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 24px;
    padding: 32px;
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
  }

  p {
    color: #ffffff;
    margin-bottom: 32px;

    @media only screen and (max-width: 575.98px) {
      br {
        display: none;
      }
    }
  }

  .form {
    max-width: 100%;
    background: transparent !important;
    padding: 0 !important;

    label {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      opacity: 0.7;
      color: #ffffff;
      margin-bottom: 4px;

      small {
        margin-left: 7px;
        color: #bcbcbc;
      }
    }

    &__inner {
      max-width: 100%;
      width: 528px;

      @media only screen and (max-width: 991.98px) {
        width: 100%;
      }

      input[type="text"],
      input[type="email"],
      textarea {
        border: 1px solid rgba(255, 255, 255, 0.16);
        border-radius: 8px;
        margin-bottom: 1rem;
        background-color: transparent;
        width: 100%;
        padding: 8px 12px;
        transition: all .25s ease;
        color: #ffffff;

        &:focus {
          outline: none;
          transform: scale(1.008);
          transition: all .25s ease;
        }
      }

      .inputGroup {
        &:nth-child(1) {
          margin-right: 2%;
        }

        &:nth-child(1),
        &:nth-child(2) {
          width: 49%;
          display: inline-block;

          @media only screen and (max-width: 575.98px) {
            width: 100%;
          }
        }
      }

      input[type=checkbox] {
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        margin: 0;
        font: inherit;
        color: #ffffff;
        width: 1rem;
        height: 1rem;
        padding: 10px;
        margin-right: .25rem;
        border: 0.15rem solid #ffffff;
        border-radius: 0.25em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;

        &:before {
          content: "";
          width: 0.65em;
          height: 0.65em;
          clip-path: polygon(
            14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%
          );
          transform: scale(0);
          transform-origin: bottom left;
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em #ffffff;
          background-color: CanvasText;
        }

        &:checked::before {
          transform: scale(1);
        }
      }

      textarea {
        min-height: 8rem;
      }

      p {
        text-align: center;
      }

      a {
        color: #DC4BA1;
        text-decoration: underline;
      }

      .check {
        text-align: center;
        padding-top: 12px;
        margin-bottom: 25px;

        input {
          margin-right: 12px;
        }
      }

      .submit-container {
        text-align: center;
        margin-bottom: 15px;

        .btn {
          border-radius: 999px;
          padding: 15px 118px;
          width: 100%;

          @media only screen and (max-width: 670px) {
            padding: 15px;
          }
        }
      }
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 100vw solid #3B1C4E;

    @media only screen and (max-width: 575.98px) {
      border-top: 50px solid transparent;
    }
  }
}
