@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/bootstrap-utilities';

.Badge {
  @extend .badge;
  @extend .me-1;

  background-color: rgba(221, 179, 222, 0.3);
  border-radius: 9px;
  color: #dd499c;
  font-size: 13px;
  text-decoration: none;

  &:hover {
    color: #dd499c;
    text-decoration: underline;
  }
}
