@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/bootstrap-utilities';

.PostCard {
  @extend .card;
  @extend .h-100;

  border: 1px solid hsla(0, 0%, 100%, .16);
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.141176);
  color: #6f6675;
  background-color: #160C1B;
  border-radius: 24px;
  overflow: hidden;
}

.CardAuthor {
  font-size: 14px;
  color: #f8e3ff;
  margin-bottom: 0.5rem;
}

.CardTitle {
  @extend .card-title;

  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  height: 100px;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      background-clip: text;
      -webkit-background-clip: text;
      background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
      color: transparent;
    }
  }
}

.CardText {
  @extend .card-text;

  font-size: 16px;
  font-weight: 400;

  p {
    color: #f8e3ff;
    display: -webkit-box;
    margin: 0;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
}

.CardFooter {
  @extend .card-footer;
  @extend .border-0;
  @extend .my-2;

  background-color: #190e2b;
  color: #707070;

  div {
    @extend .d-flex;
    @extend .flex-row;
    @extend .pt-3;

    border-top: 1px solid rgba(155, 155, 155, 0.3) !important;
  }
}

.ReadMore,
.ReadMore:hover {
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(340deg, #ef3355 30%, #c766f4 73%);
  color: transparent;

  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
