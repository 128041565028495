.Navbar {
  padding: 12px 0;
  z-index: 200;
  color: #442458;
  backdrop-filter: blur(4px);
  background-color: rgba(46, 21, 61, .8);
  box-shadow: 0 0 100px hsla(0, 0%, 0%, .3);

  @media screen and (max-width: 1055px) {
    padding-left: 80px;
  }

  @media screen and (max-width: 992px) {
    padding-left: 0;
  }

  .onlyDesktop {
    cursor: pointer;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-image .25s ease;

    &:hover {
      -webkit-text-fill-color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      background-image: linear-gradient(340deg,#ef3355 30%,#c766f4 73%);
      color: transparent;
      transition: all .25s ease;
    }
  }

  @media screen and (max-width: 1055px) {
    padding-left: 80px;
  }

  @media screen and (max-width: 992px) {
    padding-left: 0;
  }

  .navToggler {
    background: transparent;
    border: 0;
    border-radius: 4px;

    span {
      display: block;
      position: relative;
      background-image: none;
      width: 30px;
      height: 3px;
      background-color: silver;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 30px;
        height: 3px;
        background-color: silver;
        position: absolute;
      }

      &:before {
        top: -9px;
      }

      &:after {
        bottom: -9px;
      }
    }
  }
}

.ProductMenu {
  h3 {
    color: #f8e3ff;
  }

  li {
    a {
      display: block;
      width: 100%;
    }

    img {
      display: block;
      height: 40px;
    }
  }
}
