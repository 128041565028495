@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/spinners';

.Spinner {
  @extend .spinner-border;

  color: #cf5bd3 !important;
}
