@import '../../styles/effects/gradient-text';
@import '../../styles/effects/gradient-button';

.Ebook {
  padding-top: 6rem;
  height: calc(100vh - 3.5rem);
  min-height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    @extend .GradientText;
    display: inline-block;
    font-size: 40pt;
    font-weight: 900;
    text-transform: uppercase;
  }

  h3 {
    @extend .GradientText;
  }

  h4 {
    color: #ffffff;
    margin: 1rem 0;
  }

  fieldset {
    text-align: left;
  }

  .EbookForm {
    background: #190e2b;
    padding: 30px 40px 10px;
    box-shadow: 4px 4px 60px 30px rgba(0, 0, 0, .25);
    border-radius: 7px;
  }
}

.TextInput {
  background: #190e2b;
  border: 1px solid #e6e6e6;
  border-radius: .25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  color: #fff;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: calc(1.5em + 1.6rem + 2px);
  line-height: calc(1rem + 10px);
  line-height: 1.5;
  padding: .8rem 1rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-bottom: 1rem;
}

.GetTheEbook {
  @extend .GradientButton;
  display: block;
  margin: 2rem auto;
  text-transform: none;
  font-size: 16px;
  line-height: 19px;
}
