#ZeroTrust {
  position: relative;
  padding-bottom: 6rem;

  .content-tab {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #ffffff;
  }

  .bg-inactive {
    background: rgba(255, 255, 255, 0.04);
  }

  .bg-active {
    background: linear-gradient(180deg, #683484 0%, #512868 100%);
  }

  .show,
  .fade {
    height: 100%;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 0.25rem;
    border: 1px solid rgba(239, 52, 85, 0.32);

    .card {
      background: transparent;
      color: #ffffff;
      border: 0;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    border-top: 100px solid transparent;
    border-bottom: 0px solid transparent;
    border-right: 100vw solid #110716;

    @media only screen and (max-width: 575.98px) {
      border-top: 50px solid transparent;
    }
  }

  table {
    color: #ffffff;

    td {
      color: #ffffff;
    }
  }

  .box {
    padding: 24px 32px;
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 8px;
    height: 100%;
    transition: all 0.25s ease;

    &:hover {
      background: #3E0C2B;
      border: 1px solid #3E0C2B;
      box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.12);
      transition: all 0.25s ease;
    }

    h3 {
      font-size: 23px;
      font-weight: 700;
    }
  }

  .card-btn {
    @media screen and (max-width: 991px) {
      margin: 0 auto;
      justify-content: center;
    }
  }

  .video {
    width: 100%;
    height: 15rem;

    @media screen and (max-width: 1399px) {
      width: 100%;
    }

    @media screen and (max-width: 1199px) {
      height: 98%;
    }

    @media screen and (max-width: 991px) {
      display: block;
      margin: 0 auto 5rem;
    }

    @media only screen and (max-width: 575.98px) {
      margin: 0 auto 2rem;
      width: 100%;
      height: 12rem;
    }
  }
}