.Slider {
  .slick-slider {
    padding: 24px 0 84px 0;

    @media only screen and (min-width: 1200px) {
      padding: unset;
    }

    @media only screen and (max-width: 1199px) {
      .slick-list {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          z-index: 200;
          top: 0;
          bottom: 0;
          display: block;
          width: 400px;

          @media only screen and (max-width: 768px) {
            width: 20%;
          }
        }

        .slick-slide {
          position: relative;
        }
      }

      .slick-arrow {
        height: 100%;
        width: 12rem;
        z-index: 50;

        &:before {
          display: none;
        }
      }

      .slick-prev {
        display: none !important;
      }
    }

    .slick-dots {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 2rem;

      li {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        margin: 0 8px;
        transition: width .2s ease;
        background: #DA4DA8;

        &.slick-active {
          width: 45px;
          background: #C665F4;
        }

        button {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
