.BaseText {
  font-family: 'NunitoSans';
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  left: 50%;
  opacity: 0.6;
  position: absolute;
  top: 50%;
  transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  pointer-events: none;
  will-change: transform;
  white-space: pre-line;
  background-image: linear-gradient(to right, #EF3355, #C766F4, #8C66F4, #C766F4, #EF3355);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.FraudPrevention {
  @extend .BaseText;

  transform: translate(-600px, -150px) scale(0.9);

  @media only screen and (max-width: 991px) {
    transform: translate(-50px, -590px) scale(0.9);
  }

  @media only screen and (max-width: 575.98px) {
    opacity: 1;
    transform: translate(-100px, -610px) scale(0.7);
  }

  &.FraudPreventionHover {
    opacity: 1;
    transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: translate(-600px, -150px);

    @media only screen and (max-width: 991px) {
      opacity: 1;
      transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transform: translate(-50px, -590px);
    }

    @media only screen and (max-width: 575.98px) {
      transform: translate(-100px, -610px) scale(0.7);
    }
  }
}

.ContinuousAuthentication {
  @extend .BaseText;

  width: 47rem;
  transform: translate(-220px, -300px) scale(0.9);

  @media only screen and (max-width: 991px) {
    transform: translate(-140px, -170px) scale(0.9);
    width: auto;
  }

  @media only screen and (max-width: 575.98px) {
    width: 30rem;
    opacity: 1;
    transform: translate(-180px, -100px) scale(0.7);
  }

  &.ContinuousAuthenticationHover {
    opacity: 1;
    transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: translate(-220px, -300px);

    @media only screen and (max-width: 991px) {
      opacity: 1;
      transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transform: translate(-140px, -170px);
    }

    @media only screen and (max-width: 575.98px) {
      transform: translate(-180px, -100px) scale(0.7);
    }
  }
}

.ZeroTrust {
  @extend .BaseText;

  transform: translate(220px, 80px) scale(0.9);

  @media only screen and (max-width: 991px) {
    transform: translate(-120px, 150px) scale(0.9);
    width: auto;
  }

  @media only screen and (max-width: 575.98px) {
    width: 30rem;
    opacity: 1;
    transform: translate(-160px, 430px) scale(0.7);
  }

  &.ZeroTrustHover {
    opacity: 1;
    transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transform: translate(220px, 80px);

    @media only screen and (max-width: 991px) {
      opacity: 1;
      transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transform: translate(-120px, 150px);
    }

    @media only screen and (max-width: 575.98px) {
      transform: translate(-160px, 430px) scale(0.7);
    }
  }
}
