.CaptchaBotDetection {
  text-align: left;
  color: #f8e3ff;

  .Title {
    font-family: 'NunitoSans';
    font-weight: 700;
    font-size: 48px;
    line-height: 130%;

    @media only screen and (max-width: 575.98px) {
      font-size: 32px;
      text-align: center;
    }
  }

  h3 {
    @media only screen and (max-width: 575.98px) {
      text-align: center;
    }

    & + a {
      @media only screen and (max-width: 575.98px) {
        display: block;
        margin: 0 auto;
      }
    }
  }

  h4 {
    br {
      @media only screen and (max-width: 575.98px) {
        display: none;
      }
    }
  }

  p {
    margin: 0;
  }

  .Hero {
    padding: 8rem 0;
    position: relative;

    @media only screen and (max-width: 575.98px) {
      padding: 8rem 0 4rem;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0;
      border-top: 100px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 100vw solid #3B1C4E;

      @media only screen and (max-width: 575.98px) {
        border-top: 50px solid transparent;
      }
    }

    section {
      padding-top: 0;
    }
  }

  .Awards {
    section {
      padding-bottom: 0;

      &:after {
        display: none;
      }
    }
  }

  .DidYouKnow {
    padding-bottom: 8rem;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 0;
      border-top: 100px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 100vw solid #110716;

      @media only screen and (max-width: 575.98px) {
        border-top: 50px solid transparent;
      }
    }
  }

  .MazeImg {
    max-width: 100%;
    margin: 0 auto;
    display: block;

    @media only screen and (max-width: 992px) {
      margin-top: 2rem;
    }
  }

  .Blog {
    section {
      padding-bottom: 0;

      &:after {
        display: none;
      }
    }
  }

  .Box {
    background: #190e2b;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    padding: 35px 25px;
    height: 100%;

    h2 {
      font-weight: 900;
    }

    h4 {
      font-weight: 700;
    }
  }

  iframe {
    @media only screen and (max-width: 575.98px) {
      height: 220px;
      margin-top: 2rem;
    }
  }

  .GradientBg {
    background-image: linear-gradient(35deg,
        rgba(28, 12, 34, .7) 0%,
        rgba(34, 11, 50, .7) 24%,
        rgba(38, 10, 61, .7) 35%,
        rgba(40, 8, 68, .7) 43%,
        rgba(46, 7, 80, .7) 50%,
        rgba(49, 5, 87, .7) 57%,
        rgba(55, 5, 77, .7) 65%,
        rgba(65, 5, 72, .7) 72%,
        rgba(72, 5, 62, .7) 81%,
        rgba(77, 5, 49, .7) 90%,
        rgba(82, 5, 33, .7) 100%);
    box-shadow: inset 0 0 100px hsla(0, 0%, 0%, .3);
  }
}
